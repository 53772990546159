import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Icon } from 'app/components/Common/Icon'
import { Lines } from 'app/components/Common/Lines'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo, useState } from 'react'

import { DealItem, Props as DealItemProps } from './item'

export interface Props {
  deals?: DealItemProps[]
}

export const DealsList = memo(function DealsList({ deals }: Props) {
  const [activeCategory, setActiveCategory] = useState('')
  return (
    <Container>
      <Lines />
      <FadeInUp>
        <Filters>
          <Filter
            onClick={() => setActiveCategory('')}
            className={!activeCategory ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-offers-all.svg" />
            <FilterLabel>Tutte</FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveCategory('promo')}
            className={activeCategory == 'promo' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-offers-promo.svg" />
            <FilterLabel>Promo</FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveCategory('wellness')}
            className={activeCategory == 'wellness' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-offers-wellness.svg" />
            <FilterLabel>Wellness</FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveCategory('weekend')}
            className={activeCategory == 'weekend' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-offers-weekend.svg" />
            <FilterLabel>Weekend</FilterLabel>
          </Filter>
        </Filters>
      </FadeInUp>

      {deals ? (
        <List row wrap>
          {deals.map((item, index) => (
            <StyledDealItem
              index={index}
              key={index}
              {...item}
              className={
                activeCategory && activeCategory != item.category
                  ? 'hidden'
                  : ''
              }
            />
          ))}
        </List>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: -3.66vw;
  position: relative;
`

const Filters = styled.div`
  display: none; // TEMPORARY HIDDEN

  text-align: center;
  margin-bottom: 3vw;

  @media (max-width: 991px) {
    display: none;
  }
`

const FilterIco = styled(Icon)`
  transition: all 200ms linear;
`

const FilterLabel = styled.div`
  font-size: 1vw;
  font-weight: 300;
  opacity: 0.5;
`

const Filter = styled.div`
  display: inline-block;
  text-align: center;
  width: 6vw;
  margin: 0 10px;
  cursor: pointer;

  &.active {
    ${FilterIco} {
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
      img {
        filter: brightness(1000%);
      }
    }
    ${FilterLabel} {
      opacity: 1;
    }
  }

  &:hover ${FilterIco} {
    opacity: 0.8;
  }
`

const List = styled(FlexBox)`
  margin-top: 3vw;
  padding: 0 3.66vw 6.66vw;

  @media (max-width: 991px) {
    display: block;
    margin: 0;
    padding: 30px;
  }
`
const StyledDealItem = styled(DealItem)`
  &.hidden {
    display: none;
  }
`
