import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  title?: string
  subtitle?: string
  category?: string
  languageCode: string
  slug?: string
  index?: number
  className?: string
}

export const DealItem = memo(function DealItem({
  image,
  languageCode,
  title,
  subtitle,
  category,
  slug,
  className,
}: Props) {
  return (
    <Container className={`${className}`}>
      <Wrapper>
        <FadeInUp>
          <Link to={`${slug}`}>
            <StyledImage {...image} />
            <Overlay />
            <DealInfos>
              <DealPretitle>
                <img
                  src={`/icon_filter-offers-${category}.svg`}
                  alt={`ico-${category}`}
                />
                {category}
              </DealPretitle>
              <DealTitle>{title}</DealTitle>
              <DealSubtitle>{subtitle}</DealSubtitle>
              <DealCTA>
                {useVocabularyData('details', languageCode)}
                <StyledArrowCTA direction="R" />
              </DealCTA>
            </DealInfos>
          </Link>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 75%
  );
`

const DealInfos = styled.div`
  bottom: 0;
  left: 0;
  z-index: 3;
  position: absolute;
  padding: 5vw;
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
`
const DealPretitle = styled.div`
  font-size: 0.8vw;
  text-transform: capitalize;
  margin-bottom: 0.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  img {
    vertical-align: middle;
    margin-right: 10px;
    filter: brightness(1000%);
  }

  @media (max-width: 1439px) {
    font-size: 12px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`
const DealTitle = styled.h3`
  font-size: 1.66vw;
  font-weight: 300;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`
const DealSubtitle = styled.h4`
  font-size: 1.2vw;
  font-weight: 300;
  margin-bottom: 2vw;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`
const DealCTA = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  padding: 0.5vw 1vw 0.5vw 0;
  font-size: 0.9vw;
  letter-spacing: 2px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 1439px) {
    font-size: 13px;
    padding: 10px 20px 10px 0;
  }

  &::after,
  &::before {
    display: block;
    content: ' ';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 2;
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  &::after {
    width: 0;
    opacity: 1;
    transition: all 800ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
`
const StyledArrowCTA = styled(Arrow)`
  position: absolute;
  top: 50%;
  right: 0;
  background: none;
  transform: translate3d(50%, -50%, 0) scale(0.8);
`

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

const Container = styled.article`
  width: 50%;
  padding: 0 3vw;
  margin-bottom: 6vw;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  &:hover {
    ${StyledImage} img {
      transform: scale(1.1);
    }

    ${DealCTA} {
      &::after {
        width: 100%;
      }
    }
  }
`
